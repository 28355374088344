
@import "lib/_swiper";
@import "lib/_nprogress";

:root {



}

.centered-slides .swiper-wrapper {
    align-items: center;
  }