@import "swiper/swiper-bundle.css";

/*
	Pagination
*/

:root {
  --swiper-pagination-bullet-horizontal-gap: 20px;
}

.swiper-pagination {
  @apply z-40;
}

.carousel-pagination.swiper-pagination-horizontal {
	bottom: -50px;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  background: theme("colors.violet-01");
  opacity: 0.1;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}
